import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Select, Table, TableProps, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, FileWordOutlined, FileZipOutlined, RollbackOutlined, UnorderedListOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { ContractTypeEnum, FilterOperatorsEnum, IInstalmentEntity, IInventoryEntity, IItemsWithCount, IOrderEntity, KeyValueRecord, OrderStatusEnum, PermissionsEnum, transformToFormattedTime } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetItems, GetTableItems, HasAnyPermission, PutItem } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, RefundConfirmationModal } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { OrderModalComponent } from './add-edit';
import { ENGLISH } from '@i18n';
import { ViewOrderModalComponent } from './view';
import { ViewOrderInsallmentsModalComponent } from './view-installments';
import createReport from 'docx-templates';
import { ViewOrderInventoriesModalComponent } from './view-inventories';
import JSZip from 'jszip';
import Label from '../../shared/Label';

const { Content } = Layout;

export const OrderPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [ordersData, setOrdersData] = useState<IItemsWithCount<IOrderEntity>>(EMPTY_INITIAL_ITEMS);
    const [isOrderModalOpened, setIsOrderModalOpened] = useState(false);
    const [isViewOrderModalOpened, setIsViewOrderModalOpened] = useState(false);
    const [isViewInventoriesModalOpened, setIsViewInventoriesModalOpened] = useState(false);
    const [editingOrderId, setEditingOrderId] = useState('');
    const [viewOrderId, setViewOrderId] = useState('');
    const [viewOrder, setViewOrder] = useState<IOrderEntity>({} as IOrderEntity);
    const [isViewOrderInstallmentsModal, setIsViewOrderInstallmentsModal] = useState(false);
    const [contractGenerating, setContractGeneration] = useState('');
    const [zipGenerationID, setZipGenerationID] = useState('');
    const [internalID, setInternalID] = useState('');
    const [modifiedContractType, setModifiedContractType] = useState({});
    const { Option } = Select;

    const { refetch, isFetching } = useQuery<IItemsWithCount<IOrderEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['orders'],
        queryFn: () => GetTableItems<IOrderEntity>(
            'order',
            ['user', 'internal_point_of_contact', 'channel', 'inventories.procurement.product_type',
                'inventories.procurement.brand', 'instalment_plan', 'instalments', 'inventories.mobile_info',
                'inventories.procurement.vendor', 'instalments.payment_type', 'guarantor', 'delivery.compliance'],
            [],
            tableState,
            [
                [
                    {

                        field: 'is_active',
                        operator: FilterOperatorsEnum.EQUAL,
                        value: 'true',

                    }
                ]
            ]
            // filter: []
        ),
        onSuccess: (returnedResult: IItemsWithCount<IOrderEntity>): void => {
 setOrdersData(returnedResult);},
        onError: () => setOrdersData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: refundOrder } = useMutation<IOrderEntity, AxiosError, { id: any; data: Partial<IOrderEntity> }>({
        mutationKey: ['refundOrder'],
        mutationFn: async ({ id, data }) => {
            return PutItem<IOrderEntity, Partial<IOrderEntity>>('order/refund', id, data);
        },
        onSuccess: (updatedOrder: IOrderEntity) => {
            messageApi.open({
                type: 'success',
                content: `Order has been refunded successfully!`,
            });
            refetch();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Failed to process the refund.',
            });
        },
    });
    

    const { mutate: deleteOrder } = useMutation<IOrderEntity, AxiosError>({
        mutationKey: ['deleteOrder'],
        mutationFn: async () =>
            DeleteItem<IOrderEntity>(
                'order',
                editingOrderId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Order deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchContract, isFetching: isContractFetching } = useQuery<KeyValueRecord, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['contract', contractGenerating],
        queryFn: () => GetItems<KeyValueRecord>(`contract/?order_id=${contractGenerating}`),
        onSuccess: (returnedResult: KeyValueRecord): void => {
            fetchData(returnedResult)
                .then(() => {
                    setContractGeneration('');
                })
                .catch(error => {
                    setContractGeneration('');
                    messageApi.open({
                        type: 'error',
                        content: (error && error?.message) || ''
                    });
                });
        },
        onError: (error: KeyValueRecord) => {
            setContractGeneration('');
            messageApi.open({
                type: 'error',
                content: error?.response?.data?.message || error?.message || ''
            });
        }
    });

    const { refetch: fetchDocuments, isFetching: isDocumentsFetching } = useQuery<KeyValueRecord, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['zipDocuments', zipGenerationID],
        queryFn: () => GetItems<KeyValueRecord>(`order/${zipGenerationID}/documents`),
        onSuccess: (returnedResult: KeyValueRecord): void => {
            createZip(returnedResult)
                .then(() => {
                    setZipGenerationID('');
                    setInternalID('');

                })
                .catch(error => {
                    setZipGenerationID('');
                    setInternalID('');
                    messageApi.open({
                        type: 'error',
                        content: (error?.response?.status && ENGLISH.errors[error?.response?.status]()) || ''
                    });
                });
        },
        onError: (error: KeyValueRecord) => {
            setZipGenerationID('');
            setInternalID('');
            messageApi.open({
                type: 'error',
                content: error?.response?.data?.message || error?.message || ''
            });
        }
    });

    const createZip = async (documentDetails: KeyValueRecord): Promise<void> => {
        try {
            const zip: JSZip = new JSZip();

            const promises: Promise<void>[] = Object.entries(documentDetails).map(async ([name, link], index: number) => {
                const response = await axios.get(link, { responseType: 'blob' });
                zip.file(name, response.data);
            });

            await Promise.all(promises);

            const content: Blob = await zip.generateAsync({ type: 'blob' });
            const url: string = window.URL.createObjectURL(content);

            const link: HTMLAnchorElement = document.createElement('a');
            link.href = url;
            link.download = `${internalID}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            messageApi.open({
                type: 'error',
                content: ('Error fetching data or creating zip:')
            });
        }
    };
    const fetchData = async (contractDetails: KeyValueRecord): Promise<void> => {
        try {
            const response = await axios.get(contractDetails.templateURL, {
                responseType: 'arraybuffer',
                headers: {}
            });

            const report = await createReport({
                cmdDelimiter: ['{{', '}}'],
                template: response?.data,
                data: contractDetails?.contractData,
                noSandbox: true
            });
            saveDataToFile(
                report,
                `${contractDetails?.contractData?.OrderID || 'contract_unknown'}.docx`,
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            );
        } catch (error) {
            console.error('Error fetching file:', error);
            throw error;
        }
    };

    const saveDataToFile = (data: ArrayBuffer, fileName: string, mimeType: string) => {
        const blob: Blob = new Blob([data], { type: mimeType });
        const url: string = window.URL.createObjectURL(blob);
        downloadURL(url, fileName);
        setTimeout((): void => {
            window.URL.revokeObjectURL(url);
        }, 1000);
    };

    const downloadURL = (data: string, fileName: string) => {
        const a = document.createElement('a');
        a.href = data;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const { mutate: updateContractType, isLoading: isContractTypeUpdating } = useMutation<IOrderEntity[], AxiosError>({
        mutationKey: ['updateContractType'],
        mutationFn: async () => {
            return PutItem<IOrderEntity[], KeyValueRecord>('order/change/contract_type', '', modifiedContractType);
        },
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Order Updated`,
            });
            setModifiedContractType({});
            !isFetching && await refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const handleContractTypeChange = (selectedType: string, item: IOrderEntity) => {
        setModifiedContractType({ ...modifiedContractType, [item.id]: selectedType });
    };

    const onSaveChanges = () => {
        !isContractTypeUpdating && updateContractType();
    };

    const handleChange: TableProps<IOrderEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IOrderEntity>).columnKey as keyof IOrderEntity]: MapAntDesignSortOrder((sorter as SorterResult<IOrderEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onOrderModalCloseHandler = (newOrder: IOrderEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingOrderId ? {
                type: 'success',
                content: `New order: '${newOrder?.internal_id}' created!`,
            } : {
                type: 'success',
                content: `Order: '${newOrder?.internal_id}' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const resetModal = () => {
        setIsOrderModalOpened(false);
        setIsViewOrderModalOpened(false);
        setIsViewOrderInstallmentsModal(false);
        setIsViewInventoriesModalOpened(false);
        setEditingOrderId('');
        setViewOrderId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<IOrderEntity> = [
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            key: 'order_date',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('order_date', 'Use YYYY-MM-DD', resetSearchHandler)

        },
        {
            title: 'POC',
            key: 'internal_point_of_contact.first_name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            item?.internal_point_of_contact?.first_name + ' ' + item?.internal_point_of_contact?.last_name
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('internal_point_of_contact.first_name', 'by POC First Name', resetSearchHandler)
        },
        {
            title: 'ID',
            dataIndex: 'internal_id',
            key: 'internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('internal_id', 'by Order ID', resetSearchHandler)
        },
        {
            title: 'Customer Name',
            key: 'user.first_name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                          item.user?.first_name + ' ' + (item.user?.last_name?? '')
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('user.first_name', 'by Customer First Name', resetSearchHandler)
        },
        {
            title: 'CNIC Number',
            key: 'user.cnic_number',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                const user = item.user?.cnic_number;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            user
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('user.cnic_number', 'by CNIC number', resetSearchHandler)
        },
        {
            title: 'Order status',
            dataIndex: 'order_status',
            key: 'order_status',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                return <Label status={item?.order_status}>{item.order_status}</Label>
            },
            ...ColumnsSearchProps('order_status', 'by Order status', resetSearchHandler)
        },
        {
            title: 'Contract status',
            dataIndex: 'contract_status',
            key: 'contract_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('contract_status', 'by Contract status', resetSearchHandler)
        },
        {
            title: 'Compliance status',
            dataIndex: ['delivery', 'compliance', 'compliance_status'],
            key: 'delivery.compliance.compliance_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('compliance_status', 'by Compliance status', resetSearchHandler)
        },
        {
            title: 'Delivery status',
            dataIndex: ['delivery', 'delivery_status'],
            key: 'delivery.delivery_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('delivery_status', 'by Delivery status', resetSearchHandler)
        },
        {
            title: 'Contract type',
            dataIndex: 'contract_type',
            key: 'contract_type',
            ellipsis: true,
            sorter: false,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('contract_type', 'by Contract type', resetSearchHandler),
            render: (_: string, item: IOrderEntity) => (
                <Select
                    defaultValue={item.contract_type}
                    onChange={(selectedType) => handleContractTypeChange(selectedType, item)}
                >
                    {Object.values(ContractTypeEnum).map(contractType => (
                        <Option key={contractType} value={contractType}>{contractType}</Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'IMEI 1',
            key: 'inventories.mobile_info.imei_1',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
            // Retrieve imei_1 values from inventories
            const imei1Numbers: string[] | undefined = item?.inventories?.reduce((identifiers: string[], inventory: IInventoryEntity) => {
            if (inventory?.mobile_info?.imei_1) {
                return [...identifiers, inventory.mobile_info.imei_1];
            }
            return identifiers;
        }, []);

        return (
            <Tooltip>
                <span className='cursor-pointer'>
                    {imei1Numbers ? imei1Numbers.join(', ') : '-'}
                </span>
            </Tooltip>
        );
    },
    ...ColumnsSearchProps('inventories.mobile_info.imei_1', 'by IMEI 1', resetSearchHandler)
   },
   {
        title: 'IMEI 2', 
        key: 'inventories.mobile_info.imei_2',
        ellipsis: true,
        sorter: () => 0,
        render: (_: string, item: IOrderEntity) => {
        // Retrieve imei_2 values from inventories
        const imei2Numbers: string[] | undefined = item?.inventories?.reduce((identifiers: string[], inventory: IInventoryEntity) => {
            if (inventory?.mobile_info?.imei_2) {
                return [...identifiers, inventory.mobile_info.imei_2];
            }
            return identifiers;
        }, []);


        return (
            <Tooltip>
                <span className='cursor-pointer'>
                    {imei2Numbers ? imei2Numbers.join(', ') : '-'}
                </span>
            </Tooltip>
        );
       },
        ...ColumnsSearchProps('inventories.mobile_info.imei_2', 'by IMEI 2', resetSearchHandler)
    },
  
        {
            title: 'Pay Trigger ID',
            key: 'inventories.mobile_info.locking_identifier',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                const lockingIdentifiers: string[] | undefined = item?.inventories?.reduce((identifiers: string[], inventory: IInventoryEntity) => {
                    if (inventory?.mobile_info?.locking_identifier)
                        return [...identifiers, inventory.mobile_info.locking_identifier];
                    return [...identifiers];
                }, []);
                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            lockingIdentifiers
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('inventories.mobile_info.locking_identifier', 'by Pay Trigger ID', resetSearchHandler)
        },
        {
            title: 'Brand(s)',
            dataIndex: ['inventories', 'procurement', 'brand', 'name'],
            key: 'inventories.procurement.brand.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                const brandNames: (string | undefined)[] = item?.inventories ? item?.inventories?.map((inventory: IInventoryEntity) => {
                    return inventory?.procurement?.brand?.name;
                }) : [];

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            brandNames.join(', ')
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('inventories.procurement.brand.name', 'by brand name', resetSearchHandler)
        },
        {
            title: 'Model(s)',
            dataIndex: ['inventories', 'procurement', 'model'],
            key: 'inventories.procurement.model',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                const modelNames: (string | undefined)[] = item?.inventories ? item?.inventories?.map((inventory: IInventoryEntity) => {
                    return inventory?.procurement?.model;
                }) : [];

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            modelNames.join(', ')
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('inventories.procurement.model', 'by Model', resetSearchHandler)
        },
        {
            title: 'Total Purchase Price',
            key: 'total_Purchase_price',
            ellipsis: true,
            render: (_: string, item: IOrderEntity) => {
                const totalSum: number | undefined = item?.inventories ? item?.inventories?.reduce((start, inventory: IInventoryEntity) => {
                    return start + inventory?.purchase_price;
                }, 0) : undefined;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            totalSum || undefined
                        }
                    </span>
                </Tooltip>;
            },
        },
        {
            title: 'Total Retail Price',
            dataIndex: 'total_retail_price',
            key: 'total_retail_price',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('total_retail_price', 'by Total Retail Price', resetSearchHandler)
        },
        {
            title: 'Downpayment',
            dataIndex: 'downpayment',
            key: 'downpayment',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('downpayment', 'by downpayment', resetSearchHandler)
        },
        {
            title: 'Instalment Plan',
            dataIndex: ['instalment_plan', 'name'],
            key: 'instalment_plan.name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('instalment_plan.name', 'by Instalment Plan', resetSearchHandler)
        },
        {
            title: 'Total Customer Price',
            dataIndex: 'total_customer_price',
            key: 'total_customer_price',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('total_customer_price', 'by Total Customer Price', resetSearchHandler)
        },
        {
            title: 'Segment',
            key: 'segment',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                const segment = item?.segment;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            segment
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('segment', 'by segment', resetSearchHandler)
        },
        {
            title: 'Channel',
            key: 'channel.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: IOrderEntity) => {
                const channel = item.channel?.name;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            channel
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('channel.name', 'by channel name', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Installments',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, internal_id = '', total_customer_price = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<UnorderedListOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setViewOrderId(id);
                                setIsViewOrderModalOpened(true);
                                setIsViewOrderInstallmentsModal(true);
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Inventories',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, internal_id = '', total_customer_price = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<UnorderedListOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                const selectedOrder: IOrderEntity | undefined = ordersData?.items.find((order: IOrderEntity) => order.id === id);
                                if (selectedOrder)
                                    setViewOrder(selectedOrder);
                                setViewOrderId(id);
                                setIsViewInventoriesModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, internal_id = '', total_customer_price = '', order_status }) => (
                <div className='lg:flex'>
                    <Tooltip title="Download All Documents">
                        <Button
                            type='text'
                            shape='circle'
                            icon={<FileZipOutlined />}
                            disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) || !!contractGenerating}
                            onClick={
                                () => {
                                    setZipGenerationID(id);
                                    setInternalID(internal_id);
                                }
                            }
                        />
                    </Tooltip>
                    <Tooltip title="Download Contract">
                        <Button
                            type='text'
                            shape='circle'
                            icon={<FileWordOutlined />}
                            disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE]) || !!contractGenerating}
                            onClick={
                                () => {
                                    setContractGeneration(id);
                                }
                            }
                        />
                    </Tooltip>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EyeOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                const selectedOrder: IOrderEntity | undefined = ordersData?.items.find((order: IOrderEntity) => order.id === id);
                                if (selectedOrder)
                                    setViewOrder(selectedOrder);
                                setViewOrderId(id);
                                setIsViewOrderModalOpened(true);
                            }
                        }
                    />
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        //disabled={!(order_status ? [OrderStatusEnum.INCOMPLETE, OrderStatusEnum.PENDING,  OrderStatusEnum.ACTIVE].includes(order_status) : false) || !HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                const selectedOrder: IOrderEntity | undefined = ordersData?.items.find((order: IOrderEntity) => order.id === id);
                                if (selectedOrder)
                                    setViewOrder(selectedOrder);
                                setEditingOrderId(id);
                                setIsOrderModalOpened(true);
                            }
                        }
                    />
                    
                    <Button
                         className="ml-2"
                         type="text"
                         shape="circle"
                         icon={<RollbackOutlined />}
                         disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                         onClick={
                            () => {
                               setEditingOrderId(id);
 
                              const selectedOrder = ordersData.items.find((order: IOrderEntity) => order.id === id);

                              RefundConfirmationModal({
                                   content: `Are you sure you want to refund order '${selectedOrder?.internal_id}'?`,
                                   onOk: () => {
                                   refundOrder({
                                   id: selectedOrder?.id,
                                   data: {
                                   order_status: OrderStatusEnum.REFUND,
                                   internal_point_of_contact_id: selectedOrder?.internal_point_of_contact?.id, // Dynamically fetch the ID
                                   },
                               });
                                   },
                                   onCancel: () => resetModal(),
        });
    }}
                    />


                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
                        onClick={
                            () => {
                                setEditingOrderId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete user '${internal_id + ' ' + total_customer_price}'?`,
                                    onOk: () => {
                                        deleteOrder();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        if (zipGenerationID)
            !isDocumentsFetching && fetchDocuments();

        if (contractGenerating) {
            !isContractFetching && fetchContract();
        }
    }, [contractGenerating, zipGenerationID]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isOrderModalOpened &&
                <OrderModalComponent
                    editMode={!!editingOrderId}
                    id={editingOrderId}
                    onClose={onModalCloseHandler}
                    onSubmit={onOrderModalCloseHandler}
                    row={[viewOrder]}
                />
            }
            {
                isViewOrderModalOpened &&
                !isViewOrderInstallmentsModal &&
                <ViewOrderModalComponent
                    editMode={!!viewOrderId}
                    id={viewOrderId}
                    onClose={onModalCloseHandler}
                    onSubmit={onOrderModalCloseHandler}
                    row={[viewOrder]}
                />
            }
            {
                isViewInventoriesModalOpened &&
                <ViewOrderInventoriesModalComponent
                    editMode={!!viewOrderId}
                    id={viewOrderId}
                    onClose={onModalCloseHandler}
                    onSubmit={() => true}
                    extra_field={{ viewOrder }}
                    row={viewOrder.inventories}
                />
            }
            {
                isViewOrderModalOpened &&
                isViewOrderInstallmentsModal &&
                <ViewOrderInsallmentsModalComponent
                    viewMode={!!viewOrderId}
                    id={viewOrderId}
                    onClose={onModalCloseHandler}
                    onSubmit={() => true}
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card
                        title='All Orders'
                        extra={<Button
                            type='primary'
                            style={{ marginLeft: 4 }}
                            onClick={onSaveChanges}
                            disabled={!Object.values(modifiedContractType).length}
                        >
                            Save Changes
                        </Button>
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={columns}
                                dataSource={ordersData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: ordersData.count,
                                        pageSize: ordersData.pageSize,
                                        current: ordersData.page,
                                    }
                                }
                                loading={isFetching || isDocumentsFetching || isContractFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true }}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};