import { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ChequeProviderEnum, ContractStatusEnum, FilterOperatorsEnum, getEnumeratorKeys, IChannelEntity, IItemsWithCount, IOrderEntity, IUpdateOrderDTO, IUserEntity, OrderStatusEnum, removeWhiteSpaceFromAllProperties, SegmentEnumerator, UserRolesEnum } from '@rasayi-workspace/shared';
import { GetItems, PutItem, PatchItem } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { MapToSelectOption } from '@helpers';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import { SearchableDropdown } from '@components';

export const OrderModalComponent = ({ onClose, onSubmit, editMode = false, id: orderId = '', row }: AddEditModalProps<IOrderEntity>) => {
    const [form] = Form.useForm<Partial<any>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [channel, setChannel] = useState<IChannelEntity[]>([]);

    const { mutate: updateOrder, isLoading: isOrderUpdating } = useMutation<IOrderEntity, AxiosError>({
        mutationKey: ['updateOrder'],
        mutationFn: async () => {
            const updatedOrderData = removeWhiteSpaceFromAllProperties({ ...row?.[0], ...form.getFieldsValue() }) as Partial<IUpdateOrderDTO>;
    
            if (updatedOrderData.order_status === OrderStatusEnum.REFUND) {
                const inventoryIds = row?.[0]?.inventories?.map(inv => inv.id);
                    
                if (inventoryIds && inventoryIds.length > 0) {
                    try {
                        for (const inventoryId of inventoryIds) {
                           await PatchItem('inventory', inventoryId, { status: 'unsold' });
                        }
                       
                    } catch (error) {
                        messageApi.open({
                            type: 'error',
                            content: 'Error updating inventory status. Please try again.'
                        });
                    }
                } 
            }
    
            return PutItem<IOrderEntity, IUpdateOrderDTO>('order', orderId, updatedOrderData);
        },
        onSuccess: async (item: IOrderEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });
    

    const { refetch: fetchChannel, isFetching: isChannelFetching } = useQuery<IItemsWithCount<IChannelEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getChannels'],
        queryFn: () => GetItems<IChannelEntity>(
            'channel',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IChannelEntity>): void => setChannel(returnedResult.items),
        onError: () => setChannel([])
    });

    const updateFormFields = (property: string, value: string | number) => {
        form.isFieldsTouched(true);

        form.setFieldsValue({ [property]: value });

        // if (property === 'order_status') {
        //     const inventoryIds = row?.[0]?.inventories?.map(inv => inv.id);
        //     console.log('Inventory IDs:', inventoryIds);
        // }
    };

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched();
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateOrder())
        .catch(() => setIsValidForm(false));


    useEffect(() => {
        const userName = row?.[0].user?.first_name + ' ' + row?.[0].user?.last_name;
        const agentName = row?.[0].internal_point_of_contact?.first_name + ' ' + row?.[0].internal_point_of_contact?.last_name;
        form.setFieldsValue({
            ...(row?.[0] || {}),
            user_name: userName,
            agent_name: agentName
        });
        !isChannelFetching && fetchChannel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const optionsIsVerified = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Order' : 'Edit Order'}
                open={true}
                confirmLoading={
                    isOrderUpdating || isChannelFetching
                }
                // okButtonProps={{ disabled: !isValidForm }}
                okText={'Edit Order'}
                onOk={onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                okButtonProps={{ disabled: !isValidForm }}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='internal_id'
                        label='internal_id'
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name='user_name'
                        label='User '
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
    name='agent_name'
    label='POC'
>
    <Input disabled />
</Form.Item>

<Form.Item
    name='locking_identifier' 
    label='Locking identifier' 
    rules={[
        { required: true, message: 'Please enter Locking Identifier' } 
    ]}
    initialValue={row?.[0]?.inventories?.[0]?.mobile_info?.locking_identifier}

>
   
    <Input 
        placeholder="Enter Locking Identifier"
        onChange={(event) => updateFormFields('locking_identifier', event.target.value)} 
    />
    
</Form.Item>

                    <Form.Item
                        name='order_status'
                        label='Order Status'

                        rules={[
                            { required: true, message: 'Please select Order Status' }
                        ]}
                        initialValue={OrderStatusEnum.PENDING}
                    >
                        <Select onChange={(value) => updateFormFields('order_status', value)}>
                            {
                                getEnumeratorKeys(OrderStatusEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={OrderStatusEnum[key as keyof typeof OrderStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='delivery_address_line_1'
                        label='Delivery Address 1'
                        rules={[
                            { required: true, message: 'Please enter the Delivery Address' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('delivery_address_line_1', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='delivery_address_line_2'
                        label='Delivery Address 2'
                        rules={[
                            { required: false, message: 'Please enter the Delivery Address' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('delivery_address_line_2', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='delivery_city'
                        label='Delivery City'
                        rules={[
                            { required: true, message: 'Please enter the city' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('delivery_city', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='delivery_province'
                        label='Delivery province'
                        rules={[
                            { required: true, message: 'Please enter the province' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('delivery_province', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='cheque_number'
                        label='Cheque number'
                        rules={[
                            { required: true, message: 'Please enter the cheque number' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('cheque_number', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='delivery_postal_code'
                        label='Delivery Postal Code'
                        rules={[
                            { required: true, message: 'Please enter the internal_id' }
                        ]}
                    >
                        <Input
                            maxLength={5}
                            onChange={(event) => updateFormFields('delivery_postal_code', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='delivery_pin'
                        label='Delivery Pin'
                        rules={[
                            { required: false, message: 'Please enter the internal_id' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('delivery_pin', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='cheque_provider'
                        label='cheque_provider'
                        rules={[
                            { required: true, message: 'Please enter the cheque_provider' }
                        ]}
                        initialValue={ChequeProviderEnum.CUSTOMER}
                    >
                        <Select onChange={(value) => updateFormFields('customer_status', value)}>
                            {
                                getEnumeratorKeys(ChequeProviderEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={ChequeProviderEnum[key as keyof typeof ChequeProviderEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='total_retail_price'
                        label='total_retail_price'
                        rules={[
                            { required: true, message: 'Please enter the total_retail_price' },
                        ]}
                    >
                        <Input disabled
                            onChange={(event) => updateFormFields('total_retail_price', parseInt(event?.target.value) || 0)} />
                    </Form.Item>
                    <Form.Item
                        name='apr'
                        label='apr'
                        rules={[
                            { required: true, message: 'Please enter the apr' },
                        ]}
                    >
                        <Input disabled
                            onChange={(event) => updateFormFields('apr', parseInt(event?.target.value) || 0)} />
                    </Form.Item>
                    <Form.Item
                        name='downpayment'
                        label='downpayment'
                        rules={[
                            { required: true, message: 'Please enter the downpayment', }
                        ]}
                    >
                        <Input disabled
                            onChange={(event) => updateFormFields('downpayment', parseInt(event?.target.value) || 0)} />
                    </Form.Item>
                    <Form.Item
                        name='total_customer_price'
                        label='total_customer_price'
                        rules={[
                            { required: true, message: 'Please enter the total_customer_price' },
                        ]}
                    >
                        <Input disabled
                            onChange={(event) => updateFormFields('total_customer_price', parseInt(event?.target.value) || 0)} />
                    </Form.Item>
                    <Form.Item
                        name='guarantor_id'
                        label='guarantor '
                        // rules={[
                        //     { required: true, message: 'Please enter the internal_id' }
                        // ]}
                    >
                        {
                            SearchableDropdown<IUserEntity>(
                                {
                                    key: 'id',
                                    defaultValue: row?.[0].internal_point_of_contact?.cnic_number || '',
                                    sortBy: { 'cnic_number': 'ASC' },
                                    value: 'cnic_number',
                                    queryKey: ['GuarantorsUser'],
                                    queryFnParams: {
                                        apiRoute: 'user',
                                        relations: [],
                                        fields: ['id', 'first_name', 'cnic_number', 'last_name', 'primary_mobile', 'address_line1', 'city', 'email'],
                                        filter: {
                                            field: 'cnic_number',
                                            operator: FilterOperatorsEnum.LIKE,
                                            value: ''
                                        },
                                        predefinedFilters: [
                                            {
                                                field: 'roles.name',
                                                operator: FilterOperatorsEnum.EQUAL,
                                                value: UserRolesEnum.CUSTOMER
                                            }
                                        ]
                                    },
                                    onSelectionChange: (selectedItemIds: string | string[], _, items) => {
                                        updateFormFields('guarantor_id', selectedItemIds as string);
                                    }
                                }
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        name={['instalment_plan', 'name']}
                        label='instalment plan'
                        rules={[
                            { required: true, message: 'Please enter the internal_id' }
                        ]}
                    >
                        <Select
                            showSearch
                            disabled
                            placeholder='Search to Select'
                            optionFilterProp='children'
                        />
                    </Form.Item>
                    <Form.Item
                        // name='inventory'
                        label='Inventories'
                        rules={[
                            { required: true, message: 'Please select inventories' }
                        ]}
                    >
                        <Select
                            disabled
                            // value={order?.inventories?.map((inventory: IInventoryEntity) => inventory.internal_id)}
                            mode='multiple'
                            placeholder='You cannot change here'
                            optionFilterProp='children'
                        />
                    </Form.Item>
                    <Form.Item
                        name='contract_status'
                        label='contract_status'
                        rules={[
                            { required: true, message: 'Please enter the contract_status' }
                        ]}
                        initialValue={ContractStatusEnum.PENDING}
                    >
                        <Select disabled onChange={(value) => updateFormFields('contract_status', value)}>
                            {
                                getEnumeratorKeys(ContractStatusEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={ContractStatusEnum[key as keyof typeof ContractStatusEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='channel_id'
                        label='channel_id '
                        rules={[
                            { required: true, message: 'Please enter the internal_id' }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder='Search to Select'
                            optionFilterProp='children'
                            filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={MapToSelectOption(channel, 'name', 'id')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='box_and_cheque_returned'
                        label='BoxCheq Returned'
                        rules={[
                            { required: true, message: 'Please enter the BoxCheq Returned' }
                        ]}
                        initialValue={'false'}
                    >
                        <Select>
                            {optionsIsVerified.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='segment'
                        label='segment'
                        rules={[
                            { required: true, message: 'Please enter the segment' }
                        ]}
                        initialValue={SegmentEnumerator.B2C}
                    >
                        <Select onChange={(value) => updateFormFields('segment', value)}>
                            {
                                getEnumeratorKeys(SegmentEnumerator).map(
                                    (key) => <Select.Option key={key}
                                        value={SegmentEnumerator[key as keyof typeof SegmentEnumerator]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};