import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Col, ConfigProvider, DatePicker, Divider, Form, Input, Layout, Row, Select, Steps, Switch, Tag } from 'antd';
import { AprTypeEnum, ChequeProviderEnum, ContractStatusEnum, FilterOperatorsEnum, getEnumeratorKeys, IBrandEntity, IChannelEntity, IConstantsEntity, IInstalmentEntity, IItemsWithCount, IOrderEntity, IUserEntity, KeyValueRecord, OrderStatusEnum, SegmentEnumerator, UserRolesEnum } from '@rasayi-workspace/shared';
import { MapToSelectOption } from '@helpers';
import { BASE_QUERY_OPTIONS } from '@constants';
import { GetItems } from '@services';
import { BaseLayoutComponent, SearchableDropdown } from '@components';
import { orderContext } from '@contexts';
import { DeliveryInformationPageComponent } from '../delivery-information';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';

const { Content } = Layout;

export const UpdateOrderPageComponent = () => {
    const { setOrderId, setOrderDetails, orderDetails, setIncompleteOrder, setInventories, incompleteOrder, setInitialInventories, lastAccess, setGuarantorId, setUserId, setUser, setLastAccess } = useContext(orderContext);
    const [form] = Form.useForm<Partial<any>>();
    const [instalmentPlan, setInstalmentPlan] = useState<IInstalmentEntity[]>([]);
    const [selectedOrder, setSelectedOrder] = useState<any>();
    const [breakdownData, setBreakdownData] = useState<KeyValueRecord>({});
    const [nextPage, setNextPage] = useState(false);
    const [customerPricePresent, setCustomerPricePresent] = useState(false);
    const [orderDate, setOrderDate] = useState<string>('');
    const [users, setUsers] = useState<IUserEntity[]>([]);
    const [channel, setChannel] = useState<IChannelEntity[]>([]);
    const [brandDetails, setBrandDetails] = useState<KeyValueRecord>({});
    const [constants, setConstants] = useState<KeyValueRecord>({});
    const [brands, setBrands] = useState<IBrandEntity[]>([]);
    const [manualAPR, setManualAPR] = useState<boolean>(false);

    const { refetch: fetchConstants, isFetching: isConstantsFetching } = useQuery<IItemsWithCount<IConstantsEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['constantsOD'],
        queryFn: () => GetItems<IConstantsEntity>(
            'constants',
            [],
            ['id', 'name', 'value_int'],
            [
                [
                    {
                        field: 'name',
                        operator: FilterOperatorsEnum.IN,
                        value: `delivery_charges|service_charges`
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IConstantsEntity>): void => {
            const finalObject: KeyValueRecord = returnedResult?.items?.reduce((start, mid: IConstantsEntity) => {
                return { ...start, [mid.name]: parseInt(String(mid.value_int)) };
            }, {});

            setConstants(finalObject || {});
        },
        onError: () => setConstants({})
    });

    const { refetch: fetchBrands, isFetching: isBrandsFetchting } = useQuery<IItemsWithCount<IBrandEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getBrandCharges'],
        queryFn: () => GetItems<IBrandEntity>(
            'brand/charges',
            ['brand_charges'],
            [],
            [
                [
                    {
                        field: 'brand_charges.charges',
                        operator: FilterOperatorsEnum.NOT_NULL,
                        value: 'garbage'
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IBrandEntity>): void => setBrands(returnedResult.items),
        onError: () => setBrands([])
    });

    const { refetch: fetchChannel, isFetching: isChannelFetching } = useQuery<IItemsWithCount<IChannelEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getChannels'],
        queryFn: () => GetItems<IChannelEntity>(
            'channel',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IChannelEntity>): void => setChannel(returnedResult.items),
        onError: () => setChannel([])
    });

    const { refetch: fetchBreakdown, isFetching: isBreakdownFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['breakdown'],
        queryFn: () => {
            const queryParams = new URLSearchParams({
                total_retail_price: form.getFieldValue('total_retail_price'),
                downpayment: form.getFieldValue('downpayment') || 0,
                instalment_plan_Id: form.getFieldValue('instalment_plan_id'),
                order_date: form.getFieldValue('order_date'),
                apr: (form.getFieldValue('apr') || 0) || undefined,
                non_apr:
                    (form.getFieldValue('delivery_charges') || 0) +
                    (form.getFieldValue('service_charges') || 0) +
                    (form.getFieldValue('locking_charges') || 0)
            });

            return GetItems<KeyValueRecord>('order/breakdown?' + queryParams.toString());
        },
        onSuccess: (returnedResult: IItemsWithCount<KeyValueRecord>): void => setBreakdownData(returnedResult),
        onError: () => setBreakdownData({}),
    });

    const { refetch: fetchUsers, isFetching: isUsersFetching } = useQuery<IItemsWithCount<IUserEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUsers'],
        queryFn: () => GetItems<IUserEntity>(
            'user',
            [],
            ['id', 'first_name', 'cnic_number', 'last_name', 'primary_mobile', 'address_line1', 'city', 'email'],
            [
                [
                    {
                        field: 'roles.name',
                        operator: FilterOperatorsEnum.EQUAL,
                        value: UserRolesEnum.AGENT
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IUserEntity>): void => setUsers(returnedResult.items),
        onError: () => setUsers([])
    });

    const { refetch: fetchInstalmentPlan, isFetching: isInstalmentPlanFetching } = useQuery<IItemsWithCount<IInstalmentEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getInstalmentPlans'],
        queryFn: () => GetItems<IInstalmentEntity>(
            'instalment_plan',
            [],
            ['id', 'name']
        ),
        onSuccess: (returnedResult: IItemsWithCount<IInstalmentEntity>): void => setInstalmentPlan(returnedResult.items),
        onError: () => setInstalmentPlan([])
    });

    const getCharges = (): KeyValueRecord => {
        return {
            delivery_charges: form.getFieldValue('delivery_charges') ? constants['delivery_charges'] : 0,
            service_charges: form.getFieldValue('service_charges') ? constants['service_charges'] : 0
        };
    };

    const updateFormFields = (property: string | number | any, value: string | number | any) => form.setFieldsValue({ [property]: value });

    const nextPageHandler = () => {
        setOrderDetails({ ...orderDetails, ...form.getFieldsValue(), brandDetails, ...getCharges() });
        setNextPage(true);
    };

    const breakDownHandler = () => {
        if (form.getFieldValue('total_retail_price') >= form.getFieldValue('downpayment')) {
            !isBreakdownFetching && fetchBreakdown();
            setCustomerPricePresent(true);
        } else {
            form.setFieldsValue({
                'total_customer_price': undefined,
                'apr': form.getFieldValue('apr') || undefined,
                'lastInstalmentDate': undefined
            });
            setCustomerPricePresent(false);
        }
    };

    const handleDateChange = (date: dayjs.Dayjs | null, dateString: string | undefined) => {
        form.setFieldsValue({ 'order_date': date?.toISOString() });
        if (date)
            setOrderDate(date.toISOString());
    };

    useEffect(() => {
        !isUsersFetching && fetchUsers();
        !isInstalmentPlanFetching && fetchInstalmentPlan();
        !isChannelFetching && fetchChannel();
        !isConstantsFetching && fetchConstants();
        !isBrandsFetchting && fetchBrands();

        if (orderDetails?.brandDetails && !brandDetails?.name)
            setBrandDetails(orderDetails?.brandDetails);

        if (lastAccess !== 'updateOrder') {
            setInitialInventories([]);
            setOrderDetails({});
            setInventories([]);
            setGuarantorId('');
            setUserId('');
            setUser({});
            setLastAccess('updateOrder');
        }
    }, []);

    useEffect(() => {
        if (!selectedOrder?.id && orderDetails?.id) {
            form.setFieldsValue({ ...orderDetails });
            setIncompleteOrder(orderDetails.order_status === OrderStatusEnum.INCOMPLETE);
            setOrderDate(orderDetails.order_date);
            setOrderId(orderDetails.id);
            setSelectedOrder(orderDetails);
        }

        if (selectedOrder?.total_retail_price)
            !isBreakdownFetching && fetchBreakdown();

    }, [selectedOrder]);

    useEffect(() => {
        if ((breakdownData && breakdownData.totalCustomerPrice !== undefined)) {
            form.setFieldsValue({
                'total_customer_price': breakdownData.totalCustomerPrice,
                'apr': selectedOrder?.apr_type === 'manual' ? form.getFieldValue('apr') : breakdownData.aprValue,
                'lastInstalmentDate': breakdownData.lastInstalmentDate,
            });
            setCustomerPricePresent(true);
        }
    }, [breakdownData]);

    useEffect(() => {
        breakDownHandler();
    }, [manualAPR]);

    return (
        <>
            {
                nextPage &&
                <DeliveryInformationPageComponent />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Steps
                        style={{ marginTop: 20 }}
                        progressDot
                        current={0}
                        items={[
                            {
                                title: 'Order Information',
                            },
                            {
                                title: 'Delivery information',
                            },
                            {
                                title: 'Product Information',
                            },
                            {
                                title: 'Guarantor Information',
                            },
                            {
                                title: 'Complete',
                            },
                        ]}
                    />
                    <Card
                        title='Update Information'
                        extra={<>
                            <Button
                                type='primary'
                                style={{ marginLeft: 4 }}
                                disabled
                            >
                                Previous Page
                            </Button>
                            <Button
                                type='primary'
                                onClick={nextPageHandler}
                                style={{ marginLeft: 4 }}
                                disabled={!(selectedOrder && customerPricePresent)}
                            >
                                Next
                            </Button>
                        </>
                        }
                        style={{ height: 600, overflow: 'auto' }}
                    >
                        <Form
                            form={form}
                        >
                            <Row justify={'center'} gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='Order'
                                        rules={[
                                            { required: true, message: 'Please enter the Order ID' }
                                        ]}
                                    >{
                                            SearchableDropdown<IOrderEntity>(
                                                {
                                                    key: 'id',
                                                    defaultValue: orderDetails?.internal_id || '',
                                                    sortBy: { 'internal_id': 'ASC' },
                                                    value: 'internal_id',
                                                    queryKey: ['OrdersUpdate'],
                                                    queryFnParams: {
                                                        apiRoute: 'order',
                                                        relations: ['internal_point_of_contact', 'channel', 'user', 'instalment_plan', 'inventories.mobile_info', 'inventories.procurement.product_type', 'inventories.procurement.vendor', 'inventories.procurement.brand', 'guarantor'],
                                                        fields: ['internal_point_of_contact', 'internal_point_of_contact', 'channel.name'],
                                                        filter: {
                                                            field: 'internal_id',
                                                            operator: FilterOperatorsEnum.LIKE,
                                                            value: ''
                                                        },
                                                        predefinedFilters: [
                                                            {
                                                                field: 'order_status',
                                                                operator: FilterOperatorsEnum.IN,
                                                                value: `${OrderStatusEnum.INCOMPLETE}|${OrderStatusEnum.PENDING}`
                                                            },
                                                            {
                                                                field: 'is_active',
                                                                operator: FilterOperatorsEnum.EQUAL,
                                                                value: 'true',
                                                            }
                                                        ]
                                                    },
                                                    onSelectionChange: (selectedItemIds: string | string[], _, items) => {
                                                        const selectedOrder: IOrderEntity | undefined = items.find((order: IOrderEntity) => order.id === selectedItemIds);
                                                        if (selectedOrder) {
                                                            form.setFieldsValue({ ...selectedOrder });
                                                            setInitialInventories(selectedOrder?.inventories || []);
                                                            setOrderDetails(selectedOrder);
                                                            setIncompleteOrder(selectedOrder.order_status === OrderStatusEnum.INCOMPLETE);
                                                            setSelectedOrder({ ...selectedOrder, ...orderDetails });
                                                            setInventories(selectedOrder?.inventories || []);
                                                            setOrderId(selectedOrder?.id);
                                                        }
                                                    }
                                                }
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            {selectedOrder ? <>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'internal_id']}
                                            label='user.internal_id'
                                            rules={[
                                                { required: true, message: 'Please enter the user?.interal_id' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.user?.interal_id}
                                        >
                                            <Input disabled value={selectedOrder?.user?.interal_id}
                                                onChange={(event) => updateFormFields('user.internal_id', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'first_name']}
                                            label='first_name'
                                            rules={[
                                                { required: true, message: 'Please enter the user.first_name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}

                                            initialValue={selectedOrder?.user?.first_name}
                                        >
                                            <Input disabled value={selectedOrder?.user?.first_name}
                                                onChange={(event) => updateFormFields('user.first_name', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'last_name']}
                                            label='user.last_name'
                                            rules={[
                                                { required: true, message: 'Please enter the user.last_name' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.user?.last_name}
                                        >
                                            <Input disabled value={selectedOrder?.user?.last_name}
                                                onChange={(event) => updateFormFields('user.last_name', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'email']}
                                            label='user.email'
                                            rules={[
                                                { required: true, message: 'Please enter the user.email' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.user?.email}
                                        >
                                            <Input disabled value={selectedOrder?.user?.email}
                                                onChange={(event) => updateFormFields('user.email', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'cnic_number']}
                                            label='user.cnic_number'
                                            rules={[
                                                { required: true, message: 'Please enter the user.cnic_number' },
                                                { pattern: /^\d{5}-\d{7}-\d{1}$/, message: 'Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X' }
                                            ]}
                                            initialValue={selectedOrder?.user.cnic_number}
                                        >
                                            <Input value={selectedOrder?.user.cnic_number}
                                                onChange={(event) => updateFormFields('user.cnic_number', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['user', 'primary_mobile']}
                                            label='user.primary_mobile'
                                            rules={[
                                                { required: true, message: 'Please enter the user.primary_mobile' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.user?.primary_mobile}
                                        >
                                            <Input disabled value={selectedOrder?.user?.primary_mobile}
                                                onChange={(event) => updateFormFields('user.primary_mobile', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='total_retail_price'
                                            label='total_retail_price'
                                            rules={[
                                                { required: true, message: 'Please enter the total_retail_price' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.total_retail_price}
                                        >
                                            <Input value={selectedOrder?.total_retail_price}
                                                onChange={(event) => {
                                                    updateFormFields('total_retail_price', event?.target?.value ? parseInt(event.target.value) : undefined);
                                                    breakDownHandler();
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name='apr'
                                            label='apr'
                                            initialValue={selectedOrder?.apr}
                                        >
                                            <Input
                                                type='number'
                                                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                                value={selectedOrder?.apr}
                                                disabled={selectedOrder?.apr_type === 'manual' ? manualAPR : !manualAPR}
                                                onChange={(event) => {
                                                    updateFormFields('apr', event?.target?.value ? parseFloat(event.target.value) : undefined);
                                                    {
                                                        if (event?.target.value && event?.target.value !== '')
                                                            breakDownHandler();
                                                        // console.log(event?.target.value)
                                                    }
                                                }}
                                                onWheel={(e) => e.currentTarget.blur()}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name='apr_type'
                                            label='Manual APR'
                                            initialValue={selectedOrder?.apr_type === 'manual' ? !manualAPR : manualAPR}
                                        >
                                            <Switch
                                                defaultChecked={selectedOrder?.apr_type === 'manual' ? !manualAPR : manualAPR}
                                                onChange={(event) => {
                                                    if (event) {
                                                        updateFormFields('apr_type', AprTypeEnum.MANUAL)
                                                    } else {
                                                        updateFormFields('apr_type', AprTypeEnum.AUTOMATIC);
                                                    }
                                                    setManualAPR(event);
                                                }
                                                } />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='delivery_charges'
                                            label='Delivery Charges'
                                            initialValue={!!orderDetails?.delivery_charges}
                                        >
                                            <Switch
                                                defaultChecked={!!orderDetails?.delivery_charges}
                                                onChange={(event) => {
                                                    updateFormFields('delivery_charges', event ? (constants['delivery_charges'] || 400) : 0);
                                                    breakDownHandler();
                                                }
                                                } />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='brand_id'
                                            label='Brand'
                                            initialValue={orderDetails?.brandDetails?.name || ''}
                                            rules={[
                                                { required: false, message: 'Please select the brand.' },
                                            ]}
                                        >
                                            <Select
                                                placeholder='Select a Brand'
                                                optionFilterProp='children'
                                                filterOption={(input, option) =>
                                                    (String(option?.label || '').toLowerCase() ?? '').includes(input.toLowerCase() || '')
                                                }
                                                options={MapToSelectOption(brands, 'name', 'id')}
                                                showSearch={true}
                                                onChange={(value, option) => {
                                                    const selectedOption: IBrandEntity | undefined = brands.find((user: IBrandEntity) => user.id === value);
                                                    if (selectedOption) {
                                                        updateFormFields('locking_charges', selectedOption?.brand_charge?.charges);
                                                        setBrandDetails(selectedOption);
                                                    }
                                                    breakDownHandler();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='service_charges'
                                            label='Service Charges'
                                            initialValue={!!orderDetails?.service_charges}
                                        >
                                            <Switch
                                                defaultChecked={!!orderDetails?.service_charges}
                                                onChange={(event) => {
                                                    updateFormFields('service_charges', event ? (constants['service_charges'] || 600) : 0);
                                                    breakDownHandler();
                                                }
                                                } />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='locking_charges'
                                            label='Locking Charges'
                                            initialValue={brandDetails?.brand_charge?.charges || orderDetails?.brandDetails?.brand_charge?.charges || orderDetails?.locking_charges || 0}
                                        >
                                            <Input
                                                value={brandDetails?.brand_charge?.charges || orderDetails?.brandDetails?.brand_charge?.charges || orderDetails?.locking_charges || 0}
                                                disabled
                                                onChange={(event) => updateFormFields('Locking Charges', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='lastInstalmentDate'
                                            label='lastInstalmentDate'
                                            rules={[
                                                { required: true, message: 'Please enter the lastInstalmentDate' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={dayjs(breakdownData?.lastInstalmentDate) || dayjs(selectedOrder?.lastInstalmentDate)}

                                        >
                                            <ConfigProvider locale={locale}>
                                                <DatePicker
                                                    disabled
                                                    value={dayjs(breakdownData?.lastInstalmentDate)}
                                                    showTime={false}
                                                    style={{ width: '100%' }}
                                                />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='total_customer_price'
                                            label='total_customer_price'
                                            rules={[
                                                { required: true, message: 'Please enter the total_customer_price' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={breakdownData?.totalCustomerPrice}
                                        >
                                            <Input value={breakdownData?.totalCustomerPrice} disabled
                                                onChange={(event) => updateFormFields('total_customer_price', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='downpayment'
                                            label='downpayment'
                                            rules={[
                                                { required: true, message: 'Please enter the downpayment' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.downpayment}
                                        >
                                            <Input value={selectedOrder?.downpayment}
                                                onChange={(event) => {
                                                    updateFormFields('downpayment', event?.target.value ? parseInt(String(event?.target.value)) : undefined);
                                                    breakDownHandler();
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='instalment_plan_id'
                                            label='instalment_plan'
                                            rules={[
                                                { required: true, message: 'Please enter the instalment_plan_id' },
                                            ]}
                                            initialValue={selectedOrder?.instalment_plan?.id}
                                        >
                                            <Select
                                                // showSearch
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                                                filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                options={MapToSelectOption(instalmentPlan, 'name', 'id')}
                                                onChange={(selectedPlan: string) => {
                                                    updateFormFields('instalment_plan_id', selectedPlan);
                                                    breakDownHandler();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='order_date'
                                            label='order_date'
                                            rules={[
                                                { required: true, message: 'Please enter the downpayment' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.order_date ? dayjs(selectedOrder?.order_date) : undefined}
                                        >
                                            <ConfigProvider locale={locale}>
                                                <DatePicker
                                                    // disabled
                                                    value={orderDate ? dayjs(orderDate) : dayjs(selectedOrder?.order_date)}
                                                    showTime={false}
                                                    style={{ width: '100%' }}
                                                    onChange={(date, dateString) => {
                                                        handleDateChange(date, dateString);
                                                        breakDownHandler();
                                                    }}
                                                />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='order_status'
                                            label='order_status'
                                            rules={[
                                                { required: true, message: 'Please enter the order_status' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.order_status}
                                        >
                                            <Select onChange={(value) => {
                                                setIncompleteOrder(value === OrderStatusEnum.INCOMPLETE);
                                                updateFormFields('order_status', value);
                                            }}>
                                                {
                                                    getEnumeratorKeys(OrderStatusEnum).map(
                                                        (key) => {
                                                            if (OrderStatusEnum.INCOMPLETE === OrderStatusEnum[key as keyof typeof OrderStatusEnum] 
                                                                || OrderStatusEnum.PENDING === OrderStatusEnum[key as keyof typeof OrderStatusEnum]
                                                                || OrderStatusEnum.PENDING === OrderStatusEnum[key as keyof typeof OrderStatusEnum])
                                                                return (<Select.Option key={key}
                                                                    value={OrderStatusEnum[key as keyof typeof OrderStatusEnum]}>
                                                                    <Tag key={key}>{key.toUpperCase()}</Tag>
                                                                </Select.Option>);
                                                            else
                                                                return null;
                                                        }
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row gutter={16}>

                                    <Col span={12}>
                                        <Form.Item
                                            name='cheque_provider'
                                            label='cheque_provider'
                                            rules={[
                                                { required: true, message: 'Please enter the cheque_provider' }
                                            ]}
                                            initialValue={selectedOrder?.cheque_provider}
                                        >
                                            <Select
                                                onChange={(event) => updateFormFields('cheque_provider', event)}>
                                                {
                                                    getEnumeratorKeys(ChequeProviderEnum).map(
                                                        (key) => <Select.Option key={key}
                                                            value={ChequeProviderEnum[key as keyof typeof ChequeProviderEnum]}>
                                                            <Tag key={key}>{key.toUpperCase()}</Tag>
                                                        </Select.Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='delivery_city'
                                            label='delivery_city'
                                            rules={[
                                                { required: true, message: 'Please enter the delivery_city' },
                                                { min: 2, message: 'At least 2 characters' }
                                            ]}
                                            initialValue={selectedOrder?.delivery_city}
                                        >
                                            <Input value={selectedOrder?.delivery_city}
                                                onChange={(event) => updateFormFields('delivery_city', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='channel.id'
                                            label='Channel'
                                            rules={[
                                                { required: true, message: 'Please enter the internal_id' }
                                            ]}
                                            initialValue={orderDetails?.channel_id}
                                        >
                                            <Select
                                                showSearch
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                                                filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                options={MapToSelectOption(channel, 'name', 'id')}
                                                onChange={(event) => updateFormFields('channel.id', event)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='segment'
                                            label='segment'
                                            rules={[
                                                { required: true, message: 'Please enter the segment' }
                                            ]}
                                            initialValue={orderDetails?.segment || selectedOrder?.segment || SegmentEnumerator.B2C}
                                        >
                                            <Select onChange={(value) => updateFormFields('segment', value)}>
                                                {
                                                    getEnumeratorKeys(SegmentEnumerator).map(
                                                        (key) => <Select.Option key={key}
                                                            value={SegmentEnumerator[key as keyof typeof SegmentEnumerator]}>
                                                            <Tag key={key}>{key.toUpperCase()}</Tag>
                                                        </Select.Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['internal_point_of_contact', 'email']}
                                            label='POC'
                                            rules={[
                                                { required: true, message: 'Please select POC' }
                                            ]}
                                            initialValue={selectedOrder?.internal_point_of_contact?.email}
                                        >
                                            <Select
                                                showSearch
                                                placeholder='Search to Select'
                                                optionFilterProp='children'
                                                filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                                                filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                options={MapToSelectOption(users, 'email', 'email')}
                                                onChange={(event) => updateFormFields('internal_point_of_contact.email', event)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='cheque_number'
                                            label='Cheque number'
                                            rules={[
                                                { required: false, message: 'Please enter the cheque number' }
                                            ]}
                                            initialValue={selectedOrder?.cheque_number}
                                        >
                                            <Input value={selectedOrder?.cheque_number}
                                                onChange={(event) => updateFormFields('cheque_number', event?.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
    <Col span={12}>
        <Form.Item
            name={['internal_point_of_contact', 'email']}
            label='POC....'
            rules={[
                { required: true, message: 'Please select POC' }
            ]}
            initialValue={selectedOrder?.internal_point_of_contact?.email}
        >
            <Select
                showSearch
                placeholder='Search to Select'
                optionFilterProp='children'
                filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                filterSort={(optionA: any, optionB: any): any => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                options={MapToSelectOption(users, 'email', 'email')}
                onChange={(event) => updateFormFields('internal_point_of_contact.email', event)}
            />
        </Form.Item>
    </Col>

    <Col span={12}>
        <Form.Item
            name='cheque_number'
            label='Cheque number'
            rules={[
                { required: false, message: 'Please enter the cheque number' }
            ]}
            initialValue={selectedOrder?.cheque_number}
        >
            <Input
                value={selectedOrder?.cheque_number}
                onChange={(event) => updateFormFields('cheque_number', event?.target.value)}
            />
        </Form.Item>
    </Col>

    {/* IMEI 1 Field */}
    <Col span={12}>
        <Form.Item
            name='imei_1'
            label='IMEI 1'
            rules={[
                { required: false, message: 'Please enter IMEI 1' }
            ]}
            initialValue={selectedOrder?.inventories?.[0]?.mobile_info?.imei_1}
        >
            <Input
                value={selectedOrder?.inventories?.[0]?.mobile_info?.imei_1}
                onChange={(event) => updateFormFields('imei_1', event?.target.value)}
                placeholder="IMEI 1"
            />
        </Form.Item>
    </Col>

    {/* IMEI 2 Field */}
    <Col span={12}>
        <Form.Item
            name='imei_2'
            label='IMEI 2'
            rules={[
                { required: false, message: 'Please enter IMEI 2' }
            ]}
            initialValue={selectedOrder?.inventories?.[0]?.mobile_info?.imei_2}
        >
            <Input
                value={selectedOrder?.inventories?.[0]?.mobile_info?.imei_2}
                onChange={(event) => updateFormFields('imei_2', event?.target.value)}
                placeholder="IMEI 2"
            />
        </Form.Item>
    </Col>
</Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name='contract_status'
                                            label='contract_status'
                                            rules={[
                                                { required: true, message: 'Please enter the contract_status' }
                                            ]}
                                            initialValue={selectedOrder?.contract_status}
                                        >
                                            <Select onChange={(value) => updateFormFields('contract_status', value)}>
                                                {
                                                    getEnumeratorKeys(ContractStatusEnum).map(
                                                        (key) => <Select.Option key={key}
                                                            value={ContractStatusEnum[key as keyof typeof ContractStatusEnum]}>
                                                            <Tag key={key}>{key.toUpperCase()}</Tag>
                                                        </Select.Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </> : ''}
                            {/* Add more rows with Col and Form.Item as needed */}
                        </Form>
                    </Card>
                </Content>
            }
            />
        </>
    );
};