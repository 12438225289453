export enum InstalmentStatusEnum {
    PAID = 'paid',
    PAID_ON_TIME = 'paid_on_time',
    PAID_ADVANCE = 'paid_advance',
    PAID_LATE = 'paid_late',
    DUE = 'due',
    DUE_TODAY = 'due_today',
    OVERDUE = 'overdue',
    DEFAULT = 'default',
    REFUND = 'refund',
}